@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

select,
input,
textarea {
  background-color: #fff !important;
  border-radius: 2px !important;
}

.skeleton {
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: loading 1.5s infinite;
}

@keyframes loading {
  0% {
    background-position: 100% 0;
  }
  100% {
    background-position: -100% 0;
  }
}

@layer components {
  .active-indicator {
    width: 0.4rem;
    height: 2rem;
    border-left-width: 0.4rem;
    border-color: #0c3ebb;
    border-radius: 0.2rem;
    position: absolute;
    left: 0;
  }
  .reduce-width {
    width: 5rem !important;
  }
}

.truncated-url {
  max-width: 50dvw;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}

@media (max-width: 1200px) {
  .truncated-url {
    max-width: 50dvw;
  }
}

@media (max-width: 1024px) {
  .truncated-url {
    max-width: 55dvw;
  }
}

@media (max-width: 768px) {
  .truncated-url {
    max-width: 70dvw;
  }
}

@media (max-width: 480px) {
  .truncated-url {
    max-width: 70dvw;
  }
}
